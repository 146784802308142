import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EventPopoverPageRoutingModule } from './event-popover-routing.module';

import { EventPopoverPage } from './event-popover.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    EventPopoverPageRoutingModule
  ],
  declarations: [EventPopoverPage]
})
export class EventPopoverPageModule { }