import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import * as dayjs from "dayjs";
import { EventLocation } from 'src/utils/constants';
import { DbService } from '../services/db.service';
import { format, utcToZonedTime } from 'date-fns-tz';

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.page.html',
  styleUrls: ['./add-event.page.scss'],
})
export class AddEventPage implements OnInit {

  public types: EventLocation[] = [EventLocation.SPEYER, EventLocation.SCHWEGENHEIM];
  public startDate: string = dayjs().add(1, 'week').day(0).hour(10).startOf("hour").format();
  public start: string;
  public title: string = "Gottesdienst";
  public notes: string = "";
  public duration: number = 120;

  constructor(
    private db: DbService,
    private modalController: ModalController,
    private toastController: ToastController,
  ) { }

  ngOnInit() {
    this.start = this.formatDate(this.startDate);
  }

  formatDate(value: string) {
    this.duration = dayjs(value).day() === 0 ? 120 : 90;

    // Get the time zone set on the user's device
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Use date-fns-tz to convert from UTC to a zoned time
    const zonedTime = utcToZonedTime(value, userTimeZone);
    const date: string = format(zonedTime, 'dd.MM.yyyy HH:mm', { timeZone: userTimeZone });

    // Create a formatted string from the zoned time
    return date;
  }

  async addEvent() {
    if (this.title && this.types.length) {
      if (this.types.length === 1) {
        await this.db.addEvent({
          name: this.title,
          notes: this.notes,
          start: this.startDate,
          due: dayjs(this.startDate).add(this.duration, 'minutes').toISOString(),
          location: this.types[0],
        });
      } else {
        await this.db.addEvents({
          name: this.title,
          notes: this.notes,
          start: this.startDate,
          due: dayjs(this.startDate).add(this.duration, 'minutes').toISOString(),
        }, this.types);
      }

      this.dismiss(true);
    } else {
      await (await this.toastController.create({
        message: "Bitte gib einen Namen und mind. eine Kategorie an",
        duration: 1000,
      })).present();
    }
  }

  async dismiss(update: boolean = false) {
    this.modalController.dismiss(update);
  }

}
