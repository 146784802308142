import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-event-popover',
  templateUrl: './event-popover.page.html',
  styleUrls: ['./event-popover.page.scss'],
})
export class EventPopoverPage implements OnInit {
  @Input() event: any;
  @Input() isAdmin;

  constructor(
    private db: DbService,
    private popoverController: PopoverController
  ) { }

  ngOnInit() {
  }

  async remove() {
    await this.db.removeEvent(this.event.extendedProps.id);

    this.popoverController.dismiss(true);
  }

}
