import { Component } from '@angular/core';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MenuController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';
import { LANG_KEY } from 'src/utils/constants';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  private _storage: Storage | null = null;

  languagePopover = {
    showBackdrop: false,
    cssClass: 'ion-select-popover',
  };

  language = '';
  languages = [
    { text: 'DE', value: 'de' },
    { text: 'EN', value: 'en' },
  ];

  headerPages = [
    {
      routerLink: '',
      name: 'PAGES.MAIN',
    },
    {
      routerLink: '/about-us',
      name: 'PAGES.ABOUT_US',
    },
    {
      routerLink: '/events',
      name: 'PAGES.EVENTS',
    },
    // {
    //   routerLink: '/kantate',
    //   name: 'PAGES.KANTATE',
    // },
    {
      routerLink: '/contact',
      name: 'PAGES.CONTACT',
    },
    // {
    //   routerLink: '/login',
    //   name: 'PAGES.LOGIN',
    // },
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private menu: MenuController,
    private translate: TranslateService
  ) {
    this.initializeApp();
  }

  async ngOnInit() {
    await this.storage.create();
  }

  async initializeApp() {
    this._storage = await this.storage.create();

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.setInitialAppLanguge();
    });
  }

  openMenu() {
    this.menu.enable(true, 'menu');
    this.menu.open('menu');
  }

  /**************** Translate ****************/

  changeLanguage() {
    this.setLanguage(this.language);
  }

  async setInitialAppLanguge() {
    if (this._storage == null) {
      this._storage = await this.storage.create();
    }

    this._storage?.get(LANG_KEY).then((val) => {
      if (val) {
        this.translate.setDefaultLang(val);
        this.setLanguage(val);
        return val;
      } else {
        let language = this.translate.getBrowserLang();
        if (this.languages.find((l) => l.value == language) != null) {
          this.translate.setDefaultLang(language);
          this.setLanguage(language);
        } else {
          this.translate.setDefaultLang('de');
          this.setLanguage('de');
        }
        return 'de';
      }
    });
  }

  setLanguage(lang) {
    this.translate.use(lang);
    this.language = lang;
    this._storage?.set(LANG_KEY, lang);
  }
}
