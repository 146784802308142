import { Injectable } from '@angular/core';
import { createClient } from '@supabase/supabase-js';
import { environment } from 'src/environments/environment';
import { EventLocation } from 'src/utils/constants';
import { ChurchEvent } from '../utilities/interfaces';

const supabase = createClient(environment.apiUrl, environment.apiKey);

@Injectable({
  providedIn: 'root'
})
export class DbService {

  private events: ChurchEvent[] = [];
  private visited: boolean = false;

  constructor() { }

  async getEvents(refresh: boolean = false): Promise<ChurchEvent[]> {
    if (!refresh && this.events.length) {
      return this.events;
    }

    const response = await supabase
      .from<ChurchEvent>('events')
      .select('*');

    this.events = response.data;
    return response.data;
  }

  async addEvent(event: ChurchEvent): Promise<void> {
    await supabase
      .from<ChurchEvent>('events')
      .insert(event);
  }

  async addAnalytic(analytics) {
    if (!this.visited) {
      await supabase
        .from('analytics')
        .insert(analytics);

      this.visited = true;
    }
  }

  async getAnalyticsCount() {
    const { count } = await supabase
      .from('analytics')
      .select('device', { count: 'exact', head: true });

    return count;
  }

  async addPerson(kantate): Promise<void> {
    await supabase
      .from('kantate')
      .insert(kantate);
  }

  async getPersonCount() {
    let count = 0;
    const response = await supabase
      .from('kantate')
      .select('*');

    for (const entry of response.data) {
      count += entry.persons;
    }

    return count;
  }

  async addEvents(event: Partial<ChurchEvent>, types: EventLocation[]): Promise<void> {
    const events: Partial<ChurchEvent>[] = types.map((type: EventLocation) => {
      return {
        ...event,
        location: type,
      }
    });

    await supabase
      .from<ChurchEvent>('events')
      .upsert(events);
  }

  async removeEvent(id: number) {
    await supabase
      .from<ChurchEvent>('events')
      .delete()
      .match({ id });
  }
}
