export const LANG_KEY = 'translateLanguage';

export const FooterPages = [
  {
    routerLink: '',
    name: 'MAIN.CELSA_GMBH',
  },
  {
    routerLink: '/imprint',
    name: 'PAGES.IMPRINT',
  },
  {
    routerLink: '/privacy',
    name: 'PAGES.PRIVACY',
  },
];

export enum EventLocation {
  SPEYER = "Speyer",
  SCHWEGENHEIM = "Schwegenheim",
}

export enum Platforms {
  IPAD = 'iPad',
  IPHONE = 'iPhone',
  ANDROID_PHONE = 'AndroidPhone',
  ANDROID_TABLET = 'AndroidTablet',
  DESKTOP = 'Desktop',
  OTHER = 'Other',
}
